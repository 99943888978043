/* Full-screen modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Dark overlay background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal content */
.modal-content {
  max-width: 80%;
  max-height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Full-size image in the modal */
.modal-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px; /* Rounded corners if desired */
  object-fit: contain; /* Maintains aspect ratio */
}

/* Close button positioned in the top-right corner of the screen */
.close-button {
  position: fixed;
  top: 20px;
  right: 40px;
  background: transparent;
  border: none;
  color: white;
  font-size: 3rem;
  cursor: pointer;
  z-index: 1001; 
}

.close-button:hover{
  color: var(--color-red);
}

/* Responsive adjustments */
@media (max-width: 768px) {


  .close-button {
   display: none;
  }
}
