.app__serviceItem{
  background: var(--color-darkgray);
  padding: 40px;
  border-radius: 10px;
  cursor: pointer;
}

.app__serviceItem:hover{
  background: var(--color-red);
}

.app__serviceItem_headtext {
  color: var(--color-white);
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
}


.app__serviceItem_icon{
   font-size: 3rem;
   margin-bottom: 2rem;
   color: var(--color-white);
}

@media screen and (max-width: 500px) {
  .app__serviceItem{
    padding: 40px 20px;
  }
  
}
