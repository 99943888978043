.app__portfolio{
    background-color: var(--color-black);

}

.app__portfolio-content{
    margin: 2rem 0;
}

@media screen and (max-width: 750px){
  .app__portfolio-content{
    margin: 1.5rem 0;
  }
} 

@media screen and (max-width: 500px){
    .app__portfolio-content{
      margin: 1rem 0;
    }
  } 