.back-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--color-red);
    border: none;
     outline: none;
    cursor: pointer;
    font-size: 2rem;
    text-align: center;
    color: rgb(226, 226, 226);
    z-index: 10;
    
  }
  
  .back-to-top.visible {
    display: block;
    
  }

  .back-to-top:hover{
    background-color: rgb(226, 226, 226) ;
    color: var(--color-red);
    transition: 0.3s;
  }


  @media screen and (max-width:500px) {
    .back-to-top{
    right:10px;
        width: 40px;
    height: 40px;
    font-size: 1rem;
    }
    
  }
  