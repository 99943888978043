.app__about{
    background-color: var(--color-black);

}

.app__about_main{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin: 2rem 0rem;
}

.app__about_img{
    flex: 0.7;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app__about_img img{
    width: 80%;
    border-radius: 16px;
}

.about_highlighted{
    font-weight: 600;
    background: var(--color-highlight);
    color: var(--color-white);
    padding: 2px 4px;
}

.app__about-content{
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.app__about-content p{
    margin-right: 2rem;
}

.tab-links{
    font-family: var(--font-base);
    margin-right: 50px;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-grey);
    cursor: pointer;
    position: relative; /* For parent */
}

 .tab-links::after{
    content: '';
    width: 0;
    height: 3px;
    background: #ff004f;
    position: absolute;
    left: 0;
    bottom: -8px;
    transition: 0.5s;
}

.tab-links.active, .tab-links:hover{
    color: var(--color-white);
}

.tab-links.active::after{
     width: 50%;

}



.fixed-list{
    height: 250px;
    overflow: auto;
}

@media screen and (max-width: 1024px) {
    .app__about_main{
        flex-direction: column;
        
    }
}

@media screen and (max-width: 850px) {
.app__about_main{
    margin: 1.5rem 0;
}

.app__about_img{
    margin-bottom: 1rem;
}

}

@media screen and (max-width: 500px) {
    .app__about_main{
        margin: 1rem 0;
    }

    .app__about-content p{
        font-size: 1rem;
        margin-right: 1.5rem;
    }
    
}

