.app__header{
    background-color: var(--color-black);
    display: flex;
    justify-content: space-between;
    align-items: center;
   min-height: 80vh;
}

.app__header-p{
    color: var(--color-white);
    font-size: 20px;
    margin-top: 0.5rem;
}

.app__header-h1{
    color: var(--color-white);
    font-size: 5rem;
    line-height: 7rem;
    font-weight: 900;
}

.app__header-h2{
    color: var(--color-white);
    font-size: 3rem;
    font-weight: 400;
}

.app__header_highlighted{
    color: var(--color-red);
    font-weight: 600;
   
}

.app__wrapper_img img{
    width: 80%;
}

@media screen and (max-width: 1150px) {
    .app__header{
        flex-direction: column;
        min-height: auto;
    }

    #img-div {
        display: none;
    }
    
}

@media screen and (max-width: 750px ){
    .app__header-h1{
        font-size: 3rem;
        line-height: 4rem;
    }

    .app__header-h2{
        font-size: 2rem;
        line-height: 3rem;
    }

    .app__header-p{
        font-size: 16px;
    }
    
}

@media screen and (max-width: 500px) {
    .app__header-h1{
        font-size: 2.5rem;
        line-height: normal;
    }

    .app__header-h2{
        font-size: 24px;
        line-height: normal;
    }

    .app__header-p{
        font-size: 14px;
    }
}