.app__navbar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--color-black);
    padding: 1rem 6rem;
    /* position: sticky;
    top: 0;
    z-index: 1000; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  */

}

.app__navbar-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app__navbar-logo img{
    width: 150px;
}

.app__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
}

.app__navbar-links li{
    margin: 0 1.2rem;
    cursor: pointer;
}

.app__navbar-links li:hover{
    color: var(--color-hover);
}

.app__navbar-smallscreen{
    display: none;
}

.app__navbar-smallscreen_overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-black);
    transition: 0.5s ease;
    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close{
    font-size: 2rem;
    color: var(--color-white);
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links{
    list-style: none;
}

.app__navbar-smallscreen_links li{
    margin: 2rem;
    cursor: pointer;
    color:var(--color-white);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.app__navbar-smallscreen_links li:hover, .overlay__close:hover{
    color: var(--color-grey);
}

.app__navbar-link{
        font-family: var(--font-base);
        color: var(--color-white);
        font-weight: 400;
        letter-spacing: 0.04em;
        line-height: 28px;
        font-size: 18px;    
}

@media screen and (min-width: 2000px){
    .app__navbar-logo img{
        width: 210px;
    }

}

@media screen and (max-width: 1150px) {
    .app__navbar-links{
        display: none;
    }

    .app__navbar-smallscreen{
        display: flex;
    }

    .app__navbar-smallscreen_hamburger{
        cursor: pointer;
    }
    
}

@media screen and (max-width: 650px){
    .app__navbar{
        padding: 1rem;
    }

    .app__navbar-logo img{
        width: 110px;
    }

}